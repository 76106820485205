const idPortalApi = "b3566aa2fe2c094bd45189bde58f837dbcf8ab86";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "http://cmaltoalegredopindare.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmaltoalegredopindare.ma.gov.br",
  NomePortal: "Alto Alegre do Pindaré",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.planalto.gov.br/ccivil_03/leis/l9504.htm`,
  link_localize: `https://maps.app.goo.gl/XT5zzZBLvBRh9Toe6`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63706.89466775998!2d-45.85591351989745!3d-3.6599726483714834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92ca743473ff3ed1%3A0xca17b19307a82343!2sAlto%20Alegre%20do%20Pindar%C3%A9%2C%20MA%2C%2065398-000!5e0!3m2!1spt-BR!2sbr!4v1721649225143!5m2!1spt-BR!2sbr",
};
